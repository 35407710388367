/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React from 'react';
import classNames from 'classnames';
import TabsWrapper from '../../../../components/shared/Tabs/TabsWrapper';
import Tab from '../../../../components/shared/Tabs/Tab';
import { navigationStyles } from '../styles';
import { smoothScrollToElement, isIPad } from '../../../../utils';

const Navigation = ({
  location,
  blocks,
  className = '',
  full = true,
  tabStyle,
}) => {
  const classes = navigationStyles({});

  if (location.hash) {
    smoothScrollToElement(location.hash, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -187,
    });
  }

  return (
    <section
      className={classNames(
        classes.navSec,
        {
          [classes.navSecIpad]: isIPad(),
        },
        className
      )}
    >
      <TabsWrapper full={full}>
        {blocks.map((block) => (
          <Tab
            key={block.id}
            id={block.id}
            pillColor="secondary"
            customStyle={tabStyle}
          >
            {block.onClick ? (
              <p onClick={block.onClick}>{block.title}</p>
            ) : (
              block.title
            )}
          </Tab>
        ))}
      </TabsWrapper>
    </section>
  );
};

Navigation.defaultProps = {
  blocks: [],
};

export default Navigation;
