// @flow
//Core
import React from 'react';
import { Container, Typography } from '@material-ui/core';
//Local
import { previewStyles } from '../styles';

const PreviewSec = ({ title, image }: { title: string, image: string }) => {
  const styles = previewStyles({ image });

  return (
    <section className={styles.previewSec}>
      <Container maxWidth="md">
        <Typography variant="h2">{title}</Typography>
      </Container>
    </section>
  );
};

export default PreviewSec;
