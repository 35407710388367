import React from 'react';
import cx from 'classnames';
import { Container, Grid, Typography, Hidden } from '@material-ui/core';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { itemStyles } from '../styles';

const CardsSetSec = ({
  title,
  description,
  image,
  id,
  file,
  imgClassName,
}: {
  title: string,
  description: string,
  image: string,
  id: string,
  file: string,
  imgClassName?: string,
}) => {
  const styles = itemStyles();

  return (
    <section className={styles.itemSec} id={id}>
      <Container>
        <Typography variant="h2" className={styles.title}>
          {title}
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Hidden smDown>
              <ButtonPrimary
                component="a"
                target="_blank"
                rel="noreferrer noopener"
                href={file}
              >
                ダウンロードはこちら
              </ButtonPrimary>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6} className={styles.imgContainer}>
            <img
              src={image}
              alt=""
              className={cx(styles.image, imgClassName)}
            />
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12} className={styles.buttonContainerMobile}>
              <ButtonPrimary
                component="a"
                target="_blank"
                rel="noreferrer noopener"
                href={file}
              >
                ダウンロードはこちら
              </ButtonPrimary>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </section>
  );
};

CardsSetSec.defaultProps = {
  imgClassName: '',
};

export default CardsSetSec;
