/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const tabsWrapperStyles = makeStyles(() => ({
  container: {
    overflow: 'auto',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    paddingTop: 50,
    paddingBottom: 40,
    listStyle: 'none',
    overflow: 'hidden',
  },
  full: {
    justifyContent: 'space-between',
  },
}));

export const tabStyles = makeStyles((theme) => ({
  tab: {
    position: 'relative',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: 1,
    color: '#404042',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 56,
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'nowrap',
      marginRight: '50px',
    },
    '& > a': {
      color: '#404042',
    },

    '&::before': {
      content: "''",
      marginRight: '16px',
      borderRadius: '4px',
      height: '2px',
      width: '100%',
      display: 'block',
      transition: 'opacity 1s',
      opacity: 0,
      position: 'absolute',
      bottom: '-20px',
    },
  },
  primaryPill: {
    '&::before': {
      background: 'linear-gradient(90deg, #E5258C 0%, #863BD4 96.36%)',
    },
  },
  secondaryPill: {
    '&::before': {
      background: 'linear-gradient(90deg, #E5258C 0%, #863BD4 96.36%)',
    },
  },
  active: {
    fontWeight: 700,
    '&::before': {
      transition: 'opacity 2s',
      opacity: 1,
    },
  },
  full: {
    marginRight: '0px',
  },
}));
