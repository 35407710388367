import React from 'react';
import cx from 'classnames';
import { Link } from 'react-scroll';
import { useMediaQuery } from '@material-ui/core';
import { tabStyles } from './styles';

const Tab = ({
  full = false,
  id,
  children,
  pillColor = 'primary',
  customStyle,
}) => {
  const classes = tabStyles({});
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <li id={`tab-${id}`}>
      <Link
        className={cx(classes.tab, {
          [classes.full]: full,
          [classes.primaryPill]: pillColor === 'primary',
          [classes.secondaryPill]: pillColor === 'secondary',
        })}
        activeClass={classes.active}
        to={id}
        spy
        smooth
        offset={-187}
        duration={500}
        style={customStyle}
        onSetActive={() => {
          if (document && isMobile) {
            const { x } = document
              .getElementById(`tab-${id}`)
              .getBoundingClientRect();

            const elContainer = document.getElementById('navigation-container');

            elContainer.scrollTo({
              left: elContainer.scrollLeft + x - 20,
              behavior: 'smooth',
            });
          }
        }}
      >
        {children}
      </Link>
    </li>
  );
};

export default Tab;
