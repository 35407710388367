// @flow
import {
  Box,
  Typography,
  Container,
  TextField,
  Backdrop,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  TextareaAutosize,
  InputLabel,
  Grid,
  Link,
} from '@material-ui/core';
import ButtonPrimary from 'src/components/ButtonPrimary';
import cx from 'classnames';
import React, { useState } from 'react';
import axios from 'axios';
import useValidation from '../../../../hooks/useValidation';
import validators from '../../../../hooks/validators';
import { CONTACT_US_ENDPOINT } from '../../../../constants';
import { formStyles } from '../styles';
import ForwardOutboundLink from '../../../shared/ForwardOutboundLink';

const CONTACT_REASONS = {
  forIntegrate: 'ペイディの導入に関して',
  forPartnership: 'パートナーシップに関して',
  forMedia: 'メディア関連',
  forMaterials: '取材について',
  forOthers: 'その他',
};

const formValidations = {
  reasons: validators.required,
  lastname: validators.required,
  firstname: validators.required,
  phone: validators.required,
  email: validators.email,
  companyName: validators.required,
  content: validators.required,
};

const ContactForm = ({
  title,
  description,
  id,
  button,
  mode,
}: {
  title: string,
  description: string,
  id: string,
  button: string,
  mode?: string,
}) => {
  const styles = formStyles();
  const [forIntegrate, setForIntegrate] = useState(false);
  const [forPartnership, setForPartnership] = useState(false);
  const [forMedia, setForMedia] = useState(false);
  const [forMaterials, setForMaterials] = useState(false);
  const [forOthers, setForOthers] = useState(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [shopsiteURL, setShopsiteURL] = useState('');
  const [content, setContent] = useState('');

  const reasons = [];

  const values = {
    reasons: reasons.join(', '),
    lastname,
    firstname,
    phone,
    email,
    companyName,
    content,
  };

  const beforeReasonsValidate = () => {
    if (forIntegrate) {
      reasons.push(CONTACT_REASONS.forIntegrate);
    }

    if (forPartnership) {
      reasons.push(CONTACT_REASONS.forPartnership);
    }

    if (forMedia) {
      reasons.push(CONTACT_REASONS.forMedia);
    }

    if (forMaterials) {
      reasons.push(CONTACT_REASONS.forMaterials);
    }

    if (forOthers) {
      reasons.push(CONTACT_REASONS.forOthers);
    }

    values.reasons = reasons.join(', ');
  };

  // eslint-disable-next-line
  const { onValidateAll, onValidate, errors, hasError } = useValidation({
    validations: formValidations,
    values,
  });

  const onChangeText = (setter) => (event) => {
    const value = event.target.value;

    setter(event.target.value);
    onValidate({ name: event.target.name, value, checkTouch: true });
  };

  const formattedMessage = () => {
    return {
      form_type: 'contact',
      name: `${lastname} ${firstname}`,
      phone,
      email,
      corp_name: companyName,
      site_url: shopsiteURL,
      message: content,
      about1: forIntegrate ? '1' : '',
      about2: forPartnership ? '1' : '',
      about3: forMedia ? '1' : '',
      about4: forMaterials ? '1' : '',
      about5: forOthers ? '1' : '',
    };
  };

  const onChangeReasons = (setter) => (event) => {
    const checked = event.target.checked;
    const fakeReasons = !checked && reasons.length === 1 ? '' : 'fake';

    setter(checked);
    onValidate({ name: 'reasons', value: fakeReasons, checkTouch: false });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    beforeReasonsValidate();
    const _errors = onValidateAll({ checkTouch: false });

    if (Object.keys(_errors).find((key) => !!_errors[key])) {
      return;
    }

    setIsFormSubmitting(true);

    axios
      .post(CONTACT_US_ENDPOINT, formattedMessage())
      .then(() => {
        setIsFormSubmitting(false);
        setIsFormSubmitted(true);
        window.scrollTo(0, 0);
      })
      .catch(() => {
        alert('Unexpected error. Please try again later.'); // eslint-disable-line no-alert
      });
  };

  if (isFormSubmitted) {
    return (
      <Container maxWidth="lg" id={id}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight={500}
        >
          <Box textAlign="center" mb="70px">
            <Typography variant="h2" component="h4">
              お申込みありがとうございました。
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" id={id}>
      <Box maxWidth="1048px" m="0 auto" py="100px">
        <Backdrop open={isFormSubmitting} style={{ zIndex: 20 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <i id="contact_form" className="anchor" />
        <Typography variant="h2" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={styles.description}>
          {description}
        </Typography>
        <Box mt="70px">
          <form
            onSubmit={onSubmit}
            noValidate
            className={mode === 'white' ? styles.white : null}
          >
            <Box className="fieldset" pb={4}>
              <FormControl
                error={!!errors.reasons}
                component="fieldset"
                fullWidth
              >
                <Box fontWeight="700" lineHeight="26px" fontSize="16px">
                  お問い合わせ内容 ※
                </Box>
                <FormGroup>
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={forIntegrate}
                        onChange={onChangeReasons(setForIntegrate)}
                        name="cb_integrate"
                        icon={<span className={styles.icon} />}
                        checkedIcon={
                          <span
                            className={cx(styles.icon, styles.checkedIcon)}
                          />
                        }
                      />
                    }
                    label="ペイディの導入に関して"
                  />
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={forPartnership}
                        onChange={onChangeReasons(setForPartnership)}
                        name="cb_partnership"
                        icon={<span className={styles.icon} />}
                        checkedIcon={
                          <span
                            className={cx(styles.icon, styles.checkedIcon)}
                          />
                        }
                      />
                    }
                    label="パートナーシップに関して"
                  />
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={forMedia}
                        onChange={onChangeReasons(setForMedia)}
                        name="cb_media"
                        icon={<span className={styles.icon} />}
                        checkedIcon={
                          <span
                            className={cx(styles.icon, styles.checkedIcon)}
                          />
                        }
                      />
                    }
                    label="メディア関連"
                  />
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={forMaterials}
                        onChange={onChangeReasons(setForMaterials)}
                        name="cb_materials"
                        icon={<span className={styles.icon} />}
                        checkedIcon={
                          <span
                            className={cx(styles.icon, styles.checkedIcon)}
                          />
                        }
                      />
                    }
                    label="取材について"
                  />
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={forOthers}
                        onChange={onChangeReasons(setForOthers)}
                        name="cb_others"
                        icon={<span className={styles.icon} />}
                        checkedIcon={
                          <span
                            className={cx(styles.icon, styles.checkedIcon)}
                          />
                        }
                      />
                    }
                    label="その他"
                  />
                </FormGroup>
                <FormHelperText>{errors.reasons}</FormHelperText>
              </FormControl>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  className={styles.input}
                  value={lastname}
                  placeholder="例 山田"
                  id="ip_lastname"
                  name="lastname"
                  label="姓"
                  variant="standard"
                  required
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeText(setLastname)}
                  fullWidth
                  error={!!errors.lastname}
                  helperText={errors.lastname}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={styles.input}
                  value={firstname}
                  placeholder="例 太郎"
                  id="ip_firstname"
                  name="firstname"
                  label="名"
                  variant="standard"
                  required
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeText(setFirstname)}
                  fullWidth
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  className={styles.input}
                  value={phone}
                  placeholder="例 0312345678"
                  id="ip_phone"
                  name="phone"
                  label="電話番号"
                  variant="standard"
                  required
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeText(setPhone)}
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={styles.input}
                  value={email}
                  placeholder="例 sales@paidy.com"
                  id="ip_email"
                  name="email"
                  label="メールアドレス"
                  variant="standard"
                  required
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeText(setEmail)}
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  className={styles.input}
                  value={companyName}
                  placeholder="例 株式会社Paidy"
                  id="ip_companyName"
                  name="companyName"
                  label="貴社名"
                  variant="standard"
                  required
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeText(setCompanyName)}
                  fullWidth
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={styles.input}
                  value={shopsiteURL}
                  placeholder="例 未解説の場合は「未定」と入力"
                  id="ip_shopsiteURL"
                  name="shopsiteURL"
                  label="導入検討中のウェブサイトURL"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeText(setShopsiteURL)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box className="fieldset" pt="8" mb="34px">
              <InputLabel htmlFor="ip_content" filled required>
                お問い合わせ内容
              </InputLabel>
              <FormControl
                error={!!errors.content}
                component="fieldset"
                fullWidth
              >
                <Box clone className={styles.textArea}>
                  <TextareaAutosize
                    rowsMax={2}
                    rows={2}
                    rowsMin={2}
                    placeholder="例　導入を検討しています"
                    id="ip_content"
                    name="content"
                    value={content}
                    onChange={onChangeText(setContent)}
                  />
                </Box>
                <FormHelperText>{errors.content}</FormHelperText>
              </FormControl>
            </Box>
            <Typography component={Box} variant="body1" pb={5}>
              当社の
              <Link
                component={ForwardOutboundLink}
                href="https://terms.paidy.com/docs/consent-clause-regarding-personal-information.pdf"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
              >
                個人情報の取扱いに関する同意条項
              </Link>
              にご同意の上、送信を押してください。
            </Typography>
            <Box textAlign="center">
              <ButtonPrimary
                type="submit"
                variant="outlined"
                color="primary"
                className={styles.submitBtn}
              >
                {button}
              </ButtonPrimary>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

ContactForm.defaultProps = {
  mode: 'default',
};

export default ContactForm;
