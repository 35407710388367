import React from 'react';
import cx from 'classnames';
import { Button } from '@material-ui/core';
import styles from './ButtonPrimary.module.scss';

const ButtonPrimary = (props) => {
  const { className, ...restProps } = props;

  return (
    <Button
      type="submit"
      variant="outlined"
      color="primary"
      className={cx(styles.container, className)}
      {...restProps}
    />
  );
};

export default ButtonPrimary;
