/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import imgBannerMobile from 'src/images/press_kit/banner-mobile.png';
import icon from '../../../images/icon-checked-square.svg';

export const previewStyles = makeStyles(() => ({
  previewSec: {
    height: 460,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    textAlign: 'center',
    backgroundImage: (props) => `url(${props.image})`,
  },
  subtitle: {
    marginTop: 32,
  },
  '@media screen and (max-width: 600px)': {
    previewSec: {
      backgroundImage: `url(${imgBannerMobile})!important`,
    },
  },
}));

export const navigationStyles = makeStyles(() => ({
  navSec: {
    position: 'sticky',
    top: 55,
    backgroundColor: '#fff',
    zIndex: 8,
  },
  navSecIpad: {
    top: 55,
  },
}));

export const itemStyles = makeStyles(() => ({
  itemSec: {
    padding: '75px 0 75px 0',
  },
  title: {
    textAlign: 'center',
    marginBottom: 100,
  },
  description: {
    marginBottom: 70,
    maxWidth: 470,
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  image: {
    width: '100%',
    maxWidth: 'unset',
  },
  buttonContainerMobile: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 70,
  },
  '@media screen and (max-width: 1024px)': {
    description: {
      textAlign: 'center',
    },
    imgContainer: {
      alignItems: 'center',
    },
  },
  '@media screen and (max-width: 600px)': {
    description: {
      textAlign: 'left',
    },
    title: {
      fontSize: 28,
      fontWeight: 900,
      lineHeight: '40px',
      letterSpacing: 0.48,
      marginBottom: 50,
    },
  },
}));

export const formStyles = makeStyles((theme) => ({
  formSec: {
    padding: '75px 0 150px 0',
  },
  title: {
    marginBottom: 73,
    textAlign: 'center',
  },
  description: {
    marginBottom: 90,
  },
  submitBtn: {
    backgroundColor: '#404042',
    borderRadius: 25,
    minHeight: 50,
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      color: '#404042',
    },
  },
  input: {
    '& input': {
      marginTop: 10,
    },
  },
  white: {
    '& input': {
      backgroundColor: '#fff !important',
    },
    '& textarea': {
      backgroundColor: '#fff !important',
    },
  },
  icon: {
    border: '2px solid #101825',
    background: '#fff',
    borderRadius: '2px',
    width: 18,
    height: 18,
  },
  checkedIcon: {
    background: `url(${icon}) center no-repeat`,
    backgroundSize: 'cover',
    border: 'none',
  },
  textArea: {
    marginTop: 11,
    background: '#FFF',
    border: '1px solid #8D96A2',
    borderRadius: '8px',
    resize: 'none',
    font: `300 14px/24px ${theme.typography.fontFamily}`,
    marginBottom: 3,
    height: '80px!important',
    paddingTop: 25,
  },
  '@media screen and (max-width: 600px)': {
    textBottom: {
      textAlign: 'left',
    },
    formControlLabel: {
      width: '100%',
    },
    submitBtn: {
      width: '100%!important',
    },
    title: {
      fontSize: 28,
      fontWeight: 900,
      lineHeight: '40px',
      letterSpacing: 0.48,
    },
  },
}));
